<template>
  <div>
    <BaseAlert
      v-if="!isSaved"
      type="info"
      text="Подождите..."
    />
    <div
      v-else
      class="pl-1 d-flex align-center flex-column content-images"
    >
      <div class="d-flex flex-row align-center justify-center">
        <BaseUploaderFile
          v-if="defer(1)"
          class="pa-1 mt-3"
          v-show="!disabled"
          single-line
          accept-types="image/png, image/jpeg, image/jpg"
          :disabled="isLoading"
          @load="uploadPhoto"
          title="Загрузить фото"
          multiple
          id="images"
        />
        <BaseBtn
          title="Сделать фото"
          icon="mdi-camera"
          single-line
          color="primary"
          v-if="value.properties.state !== 2"
          small
          @click="uploadPhotoFromCamera"
        />
      </div>
      <div
        class="text-subtitle-1 pa-1"
        v-if="defer(1) && !disabled"
      >
        Максимальный размер одной фотографии <span class="blue--text">{{ bytesToSize(maxImageSize, ' ') }}</span>
      </div>

      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
        v-if="isLoading"
        style="width: 100%"
        class="mb-3"
        :class="{'px-0': $vuetify.breakpoint.xsOnly }"
      ></v-progress-circular>
      <NoDocs v-if="photos.length === 0 && !isLoading"/>
      <FancyBox
        :options="{
           Carousel: {
           infinite: false
          },
           Thumbs: {
           showOnStart: false
          }
        }"
      >
        <div class="d-flex flex-wrap images pl-1 justify-center">
          <Card
            v-if="photos.length > 0 && defer(2)"
            v-for="item in photos"
            :key="item.id"
            max-width="250"
            :max-height="heightCard"
            class="pa-2 align-center d-flex flex-column"
          >
            <template #content>
              <div class="pl-1 pt-1 d-flex justify-space-between flex-row align-top" style="width: 100%">
                <p class="ma-0 black--text caption date-create">{{ timestampToDate(item.image.created_at) }}</p>
                <v-icon
                  v-if="item.id && isOnline || !!item.isSystem"
                  class="ml-auto"
                  @click="handleDelete(item.image.id)"
                  v-show="!disabled"
                  :disabled="isLoading"
                  color="red"
                >mdi-delete-outline
                </v-icon>
              </div>
              <a
                data-fancybox="gallery"
                :data-src="isOnline ? getFullImageUrl(item.image.path, item.image.file_name) : item.image.path"
                :data-caption="item.image.description"
                class="img-link"
              >
                <v-img
                  max-height="100"
                  max-width="250"
                  min-height="150"
                  contain
                  style="cursor: pointer"
                  :src="isOnline || item.id ? getThumbnailUrl(item.image.path, item.image.file_name) : item.image.path"
                  lazy-src="@/assets/gray.png"
                ></v-img>
              </a>
              <div
                class="d-flex flex-row align-center justify-center description-container mt-1"
                v-if="withDescription"
              >
                <BaseBtn
                  is-type-icon
                  :icon="item.isEditModeDescription ? 'mdi-content-save' : 'mdi-pencil-outline'"
                  class="mr-1"
                  color="#64C7FF"
                  @click="handleEditDescr(item)"
                  :disabled="disabled"
                  v-if="item.id && isOnline || !!item.isSystem"
                />
                <p
                  v-show="!item.isEditModeDescription && isOnline || !item.isEditModeDescription && !item.id"
                  class="mb-1 mr-2 description-image font-weight-medium"
                  @click="handleEditDescr(item)"
                >
                  {{ localDescr(item.image) | truncate(25, '...')}}
                </p>
                <v-textarea
                  placeholder="Описание"
                  outlined
                  v-model="item.image.description"
                  dense
                  no-resize
                  hide-details
                  height="45"
                  v-show="item.isEditModeDescription"
                  class="description-image-textarea"
                >
                </v-textarea>
              </div>
            </template>
          </Card>
        </div>
      </FancyBox>
      <BaseDialog
        role="delete"
        label="Действительно удалить изображение?"
        v-model="isDeleteDialog"
        @confirm="deletePhoto"
        :max-width="350"
        is-confirm-btn
        is-cancel-btn
      />
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import BaseDialog from '@/components/base/BaseDialog'
import defer from '@/components/mixins/defer'
import { baseUrl, maxImageSize } from '@/config/common'
import formatters from '@/components/mixins/formatters'
import BaseAlert from '@/components/base/UI/BaseAlert'
import BaseUploaderFile from '@/components/base/BaseUploaderFile'
import { cleanClone } from '@/components/utils/common'
import watchValue from '@/components/mixins/watchValue'
import BaseBtn from '@/components/base/UI/BaseBtn'
import FancyBox from '@/components/base/FancyBox'
import getPath from '@/components/mixins/getPath'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { isOffline } from '@/components/utils/mobile/common'
import {
  addSystemPhotoInNew,
  changeSystemPhoto,
  deleteSystemPhoto,
  getNewObjects,
  getSystemPhotos,
  writeSystemPhoto
} from '@/components/utils/mobile/filesystemInteraction'
import isOnline from '@/components/mixins/mobile/isOnline'
import NoDocs from '@/components/base/BaseDocuments/NoDocs'
import Card from '@/components/base/UI/Card'

//note: компонент переделан. Теперь @input будет возвращать целиком детали. обязательно нужно передать nameObjectImg('passportImages' или 'objectImages')
export default {
  name: 'BasePhoto',
  components: { Card, BaseBtn, BaseUploaderFile, BaseAlert, BaseDialog, BaseHeader, FancyBox, NoDocs },
  mixins: [defer, formatters, watchValue, getPath, isOnline],
  props: {
    modelName: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: false
    },
    disabled: { Boolean, default: false }, //пропс передается из PassportObjectDetail, идет проверка роли "auditor",
    value: Object,
    nameObjectImg: String,
    withDescription: Boolean
  },
  data () {
    return {
      detail: this.value,
      baseUrl,
      isOpenDialog: false,
      isDeleteDialog: false,
      isLoading: false,
      activeImage: {},
      uploadFile: [],
      photos: this.value?.properties[this.nameObjectImg] || [],
      deleteId: null,
      maxImageSize,
      isSaved: false
    }
  },
  computed: {
    heightCard () {
      let defaultHeight = 250
      if (this.withDescription) {
        defaultHeight += 100
      }
      return defaultHeight
    },
    async newObject () {
      const newObjects = await getNewObjects(this.detail?.properties.passport_id)
      return newObjects
        ?.find((item) => item?.properties?.id === this.detail.properties.id)
    }
  },
  methods: {
    async checkSaved () {
      this.isSaved = !!this.modelId
      if (!this.isOnline && !this.modelId) {
        this.isSaved = (await this.newObject)
      }
    },
    async updateLocalImages () {
      const photos = await getSystemPhotos(this.value?.properties.passport_id, this.value?.properties.id)
      const localPhotos = this.detail?.properties[this.nameObjectImg].map((item) => item.image.id)
      const ph = photos.filter(item => !localPhotos.includes(item?.image?.id)) //удаляю из системных фото те, которые есть локально. Чтобы они не продублировались
      if (ph.length) {
        this.photos = this.detail?.properties[this.nameObjectImg].concat(ph)
      } else {
        this.photos = this.detail?.properties[this.nameObjectImg]
      }
    },
    async uploadPhotoFromCamera () {
      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.DataUrl, // Тип результата (URI)
        source: CameraSource.Camera, // Источник (камера)
        pickImages: true,
        promptLabelHeader: ''
      })
      await this.uploadPhoto([this.dataURItoFile(image.dataUrl, '.' + image.format)])
    },
    dataURItoFile (dataURL, fileName) {
      const arr = dataURL.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], fileName, { type: mime })
    },
    async addPhotoInSystem (uploadFile) {
      if (uploadFile) {
        const newObject = await this.newObject
        await writeSystemPhoto(this.detail.properties.passport_id, this.detail.properties.id, uploadFile, newObject)
        await this.updateLocalImages() //обновить текущие фото
        this.$set(this.detail.properties, this.nameObjectImg, this.photos)
        this.$emit('input', cleanClone(this.detail))
      }
    },
    async uploadPhoto (uploadFile) {
      this.isLoading = true
      if (uploadFile.length > 0 && this.validationPhoto(uploadFile)) {
        if (!this.isOnline) {
          await this.addPhotoInSystem(uploadFile)
        } else {
          const formData = new FormData()
          for (let i = 0; i < uploadFile.length; i++) {
            formData.append(`files[${i}]`, uploadFile[i])
          }
          formData.append('modelName', this.modelName)
          formData.append('modelId', String(this.modelId))
          const response = await this.$store.dispatch('api/post', { url: '/image', data: formData }, { root: true })
          if (response.success) {
            this.photos = this.photos.concat(this.addEditModeAttr(response.data))
            this.$set(this.detail.properties, this.nameObjectImg, this.photos)
            await this.$store.dispatch('systemMessages/success', this.$t('message.successLoadPhoto'), { root: true })
          }
          this.$emit('input', cleanClone(this.detail))
        }
      } else if (uploadFile) {
        if (!this.isOnline) {
          await this.addPhotoInSystem(uploadFile)
        } else {
          const response = await this.$store.dispatch('api/post', {
            url: '/image',
            data: { data: uploadFile }
          }, { root: true })
          if (response.success) {
            await this.$store.dispatch('systemMessages/success', 'Описание добавлено', { root: true })
          }
        }
      }
      this.isLoading = false
    },
    async handleEditDescr (File) {
      if (File.isEditModeDescription) {
        File.image.description_date = new Date().toLocaleString('ru-RU', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })
        if (this.isOnline) {
          await this.uploadPhoto(File.image)
        } else {
          File.isEditModeDescription = false
          await changeSystemPhoto(this.detail.properties.passport_id, this.detail.properties.id, File)
          await this.updateLocalImages()
        }
      } else {
        File.isEditModeDescription = !File.isEditModeDescription
      }
    },
    localDescr (item) {
      return item.description ? item.description : 'Добавить описание'
    },
    validationPhoto (value) {
      let isError = true
      if (value) {
        value.map((item) => {
          if (item?.size > maxImageSize) {
            isError = false
            this.$store.dispatch(
              'systemMessages/error',
              `Размер фото должен быть не больше ${this.bytesToSize(maxImageSize, ' ')}: ` + item.name,
              { root: true }
            )
          }
        })
      } else {
        isError = false
      }
      return isError
    },
    async deletePhoto () {
      this.isLoading = true
      if (this.isOnline) {
        const response = await this.$store.dispatch('api/delete', { url: '/image?id=' + this.deleteId }, { root: true })
        if (response.success) {
          this.photos = this.photos.filter((item) => item.image.id !== this.deleteId)
          this.$set(this.detail.properties, this.nameObjectImg, this.photos)
          await this.$store.dispatch('systemMessages/success', this.$t('message.successDeletePhoto'), { root: true })
        }
        this.$emit('input', cleanClone(this.detail))
      } else {
        if (!(await this.newObject)) { //если не новый объект
          await deleteSystemPhoto(this.detail.properties.passport_id, this.detail.properties.id, this.deleteId)
          this.photos = this.photos.filter((item) => item.image.id !== this.deleteId)
        } else {
          this.photos = this.photos.filter((item) => item.image.id !== this.deleteId)
          await addSystemPhotoInNew(this.detail.properties.passport_id, this.photos, this.detail.properties.id)
        }
        this.$set(this.detail.properties, this.nameObjectImg, this.photos)
        this.$emit('input', cleanClone(this.detail))
      }
      this.isLoading = false
      this.isDeleteDialog = false
      this.deleteId = null
    },
    handleDelete (id) {
      this.deleteId = id
      this.isDeleteDialog = true
    },
    addEditModeAttr (array) {
      return array = array.map(item => {
        return {
          ...item,
          isEditModeDescription: false
        }
      })
    }
  },
  async mounted () {
    this.photos = this.addEditModeAttr(this.photos)
    this.isOnline = !await isOffline()
    !this.isOnline && await this.updateLocalImages()
    await this.checkSaved()
    this.$nextTick(() => {
      if (!this.value.properties.id) {
        this.$emit('save', cleanClone(this.value))
      }
    })
  }
}
</script>

<style>
  .description-image-textarea textarea {
    max-height: 70px;
    line-clamp: 5; /* максимальное количество отображаемых строк */
    -webkit-line-clamp: 5;
    word-break: break-word;
    overflow: hidden;
    line-height: 1.2;
    text-overflow: ellipsis;
    margin: 8px 0;
    min-width: 180px;
  }
  .content-images {
    height: calc(100vh - 75px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .images {
    gap: 20px 10px;
  }
  .description-image {
    max-height: 76px;
    line-clamp: 5; /* максимальное количество отображаемых строк */
    -webkit-line-clamp: 5;
    word-break: break-word;
    overflow: hidden;
    line-height: 1.2;
    text-overflow: ellipsis;
  }
  @media (max-width: 1500px) {
    .photo-in-dialog > .v-responsive__content {
      width: 700px !important;
    }
  }
  .delete-img {
    position: absolute;
    right: 5px;
    top: -0px;
    background: rgba(216, 216, 216, 0.75);
    border-radius: 30px !important;
  }
  .date-create {
    position: relative;
    color: black !important;
    left: 10px;
    bottom: 6px;
    z-index: 1;
  }
  .container-image {
    position: relative;
  }
</style>
